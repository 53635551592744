<template>
  <b-container fluid>
    <b-row class="justify-content-center mt-5">
      <b-col xl="8" lg="10" cols="12">
        <b-card no-body>
          <PulsCardHeader
            :title="$t('integrations.title')"
            :leftAction="{
              to: { name: 'overview', title: $t('integrations.back') }
            }"
          />

          <b-card-body class="mb-3">
            <div v-if="loading" class="mt-5 d-flex justify-content-center">
              <span class="spinner-border spinner-border text-primary"></span>
            </div>
            <div v-if="!loading && error" class="ml-4 mt-3 text-danger">
              {{ error }}
            </div>
            <div v-if="!loading && !error">
              <div v-if="integrations.length">
                <b-list-group>
                  <b-list-group-item
                    v-for="integration in integrations"
                    :key="integration.name"
                  >
                    <div
                      class="my-3 d-flex w-100 justify-content-between align-items-center"
                    >
                      <h3>
                        {{ titleForIntegration(integration) }}
                      </h3>
                      <span>
                        {{ dateAddedForIntegration(integration) }}
                      </span>
                    </div>
                    <p class="mb-2">
                      {{ descriptionForIntegration(integration) }}
                    </p>
                    <p
                      class="d-flex align-items-center justify-content-between"
                    >
                      {{ detailedDescriptionForIntegration(integration) }}
                      <b-button
                        @click="removeIntegration(integration.name)"
                        v-b-popover.hover
                        variant="link"
                        :title="
                          $t('integrations.buttons.deleteIntegration.help', {
                            type: titleForIntegration(integration)
                          })
                        "
                        class="btn-sm float-right"
                      >
                        <i class="bi bi-trash3-fill fs-3 text-danger me-5"></i>
                      </b-button>
                    </p>
                  </b-list-group-item>
                </b-list-group>
                <div
                  class="add-integration-success text-success d-flex align-items-center"
                  v-if="$attrs && $attrs.addIntegrationSuccess"
                >
                  <i class="bi bi-check fs-3 me-3"></i>

                  <span> {{ $t('integrations.addIntegrationSuccess') }} </span>
                </div>
              </div>
              <div
                v-else
                class="alert text-info w-xl-50 mt-4 d-flex align-items-center"
              >
                <i class="bi bi-info-circle-fill fs-3 text-info me-3"></i>

                <span>{{ $t('integrations.empty.description') }}</span>
              </div>
            </div>
            <div v-if="!loading && !error" class="mt-5">
              <div
                v-for="integration in integrationsAvailable"
                :key="integration.appName"
              >
                <a
                  v-if="integration.connectButton"
                  :href="integration.connectButton.url"
                >
                  <img
                    v-if="integration.connectButton.img"
                    :alt="integration.connectButton.img.alt"
                    :height="integration.connectButton.img.height"
                    :width="integration.connectButton.img.width"
                    :rclt="integration.connectButton.img.src"
                    :srcSet="integration.connectButton.img.srcSet"
                  />
                  <span v-else>
                    {{ $t(`integrations.apps.${integration.appName}.title`) }}
                  </span>
                </a>

                <b-button
                  v-else
                  variant="primary"
                  class="btn-sm"
                  :hidden="!integration.enabled"
                >
                  {{ $t(`integrations.apps.${integration.appName}.title`) }}
                </b-button>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { DateTime } from 'luxon'
import { PulsCardHeader } from '@puls-solutions/puls-ui-components'

// https://slack.com/oauth/authorize?client_id=416200280944.2239525256932&redirect_uri=https://www.puls-solutions.com/integration_callback/index.html?app=slack&scope=incoming-webhook&user_scope=
// https://slack.com/oauth/authorize?client_id=416200280944.2239525256932&redirect_uri=https://dev.pulsmeter.net/integration_callback/index.html?app=slack&scope=incoming-webhook&user_scope=

export default {
  name: 'Integrations',
  components: { PulsCardHeader },
  computed: {
    loading() {
      return this.$store.getters.integrationsLoading
    },
    error() {
      return this.$store.getters.integrationsError
    },
    integrations() {
      return this.$store.getters.integrations || []
    },
    integrationsAvailable() {
      return (
        this.$store.getters.integrationsAvailable.integrationsAvailable || []
      )
    },
    groupsAvailable() {
      return this.$store.getters.integrationsAvailable.groupsAvailable || []
    },
    hostname() {
      return window.location.protocol + '//' + window.location.host
    },
    slackAuthUrl() {
      return `https://slack.com/oauth/authorize?client_id=416200280944.2239525256932&redirect_uri=${this.hostname}/integration_callback/index.html?app=slack&scope=incoming-webhook&user_scope=`
    }
  },
  methods: {
    loadIntegrations() {
      this.$store.dispatch('loadIntegrations')
    },
    loadIntegrationsAvailable() {
      this.$store.dispatch('loadIntegrationsAvailable')
    },
    removeIntegration(integrationName) {
      this.$store.dispatch('removeIntegration', { integrationName })
    },
    groupTitleForGroupName(groupName) {
      const foundGroups = this.groupsAvailable.filter(
        (g) => g.name === groupName
      )
      if (foundGroups.length) {
        return this.$tr(foundGroups[0].details.messages, 'title')
      } else {
        return '?' // if group is not found, we must fail during event handling
      }
    },
    titleForIntegration(integration) {
      const appDetail =
        integration.type === 'slack'
          ? integration.oAuthData.incoming_webhook.channel
          : ''
      return this.$t(`integrations.apps.${integration.type}.connectionTitle`, {
        appName: this.$t(`integrations.apps.${integration.type}.title`),
        appDetail
      })
    },
    descriptionForIntegration(integration) {
      if (integration.type === 'slack') {
        const teamName = integration.oAuthData.team_name
        const channel = integration.oAuthData.incoming_webhook.channel
        return this.$t('integrations.apps.slack.connectionInfo', {
          teamName,
          channel
        })
      } else {
        return integration.type
      }
    },
    detailedDescriptionForIntegration(integration) {
      const groupTitle = this.groupTitleForGroupName(
        integration.details.groupName
      )
      return integration.details.events
        .map((eventName) => {
          return this.$t(`integrations.events.${eventName}.description`, {
            groupTitle
          })
        })
        .join(' - ')
    },
    dateAddedForIntegration(integration) {
      const date = DateTime.fromISO(integration.date_created).toLocaleString(
        DateTime.DATE_MEDIUM
      )
      return this.$t('integrations.connectedSince', { date })
    }
  },
  mounted() {
    this.loadIntegrationsAvailable()
    this.loadIntegrations()
  }
}
</script>

<style type="scss" scoped></style>
